import React from "react";

const DateRangeArrow = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5764_1091)">
        <path
          d="M15 7.5L9 13.5M15 7.5L9 1.5M15 7.5L1 7.5"
          stroke="#519ACC"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5764_1091">
          <rect
            width="14"
            height="16"
            fill="white"
            transform="translate(16 0.5) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DateRangeArrow;
