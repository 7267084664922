import React from "react";
import styled from "styled-components";

const TeamCapacity = ({ teamData }) => {
  const calculatePercentage = (team) => {
    const { booked, vacation, unavailable, available } = team.team_total;
    const totalCapacity = booked + vacation + unavailable + available;
    if (totalCapacity === 0) return 0;
    return Math.round(
      ((booked + vacation + unavailable) / totalCapacity) * 100
    );
  };

  return (
    <TeamCapacityContainer>
      <Title>Team Capacity</Title>
      <TeamCapacityList>
        {teamData.map((team, index) => {
          const percentage = calculatePercentage(team);

          return (
            <TeamCard key={index}>
              <CircularProgress percentage={percentage}>
                <span>{percentage + "%"}</span>
              </CircularProgress>
              <TeamDetails>
                <h3>{team.team_name}</h3>
                <p>{team.team_members} Members</p>
              </TeamDetails>
              <Metrics>
                <MetricText>
                  <span>Booked</span>
                  <MetricHours>{team.team_total.booked.toFixed(2)}</MetricHours>
                </MetricText>
                <MetricText>
                  <strong>Vacation</strong>
                  <MetricHours>
                    {team.team_total.vacation.toFixed(2)}{" "}
                  </MetricHours>
                </MetricText>
                <MetricText>
                  <strong>Unavailable</strong>
                  <MetricHours>
                    {team.team_total.unavailable.toFixed(2)}
                  </MetricHours>
                </MetricText>
                <MetricText>
                  <strong>Available</strong>
                  <MetricHours>
                    {team.team_total.available.toFixed(2)}
                  </MetricHours>
                </MetricText>
              </Metrics>
            </TeamCard>
          );
        })}
      </TeamCapacityList>
    </TeamCapacityContainer>
  );
};

const TeamCapacityContainer = styled.div`
  padding: 24px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  background-color: #fff;
  width: 100%;
  max-height: 536px;
  overflow-y: auto;
`;

const TeamCapacityList = styled.div`
  max-height: 450px;
  overflow-y: scroll;
`;
const Title = styled.h2`
  font-size: 16px;
  font-weight: 600;
  font-family: Manrope;
  margin-bottom: 16px;
  margin-top: 0px;
`;

const TeamCard = styled.div`
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const CircularProgress = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: conic-gradient(
    #519acc ${(props) => props.percentage * 3.6}deg,
    #d3d3d3 0deg
  );
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    font-family: Manrope;
    color: #000;
    position: absolute;
    width: 35px;
    height: 35px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const TeamDetails = styled.div`
  flex: 1;
  margin-left: 16px;
  font-family: Manrope;

  h3 {
    font-size: 15px;
    margin: 0;
    font-weight: 700;
  }

  p {
    margin: 4px 0 0;
    font-size: 14px;
    font-weight: 400;
    font-family: Arial;
  }
`;

const Metrics = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 600;
  font-size: 14px;
  font-family: Manrope;

  span {
    display: flex;
    flex-direction: column;
    align-items: center;

    strong {
      font-weight: 600;
    }
  }
`;

const MetricText = styled.span`
  font-weight: 600;
  font-family: Manrope;
  span {
    font-size: 14px;
  }
`;

const MetricHours = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  font-family: Manrope;
`;

export default TeamCapacity;
