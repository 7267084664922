import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Doughnut, Line } from "react-chartjs-2";
import DropdownMenu from "components/common/DropdownMenu";

const BreakdownContainer = ({
  chartData = {},
  doughnutData = {},
  startDate,
  endDate,
}) => {
  console.log("chartData", chartData);

  const [selectedOption, setSelectedOption] = useState({});
  const [selectedDataKey, setSelectedDataKey] = useState("all");

  const options = [
    { value: 1, label: "All" },
    { value: 2, label: "Booked" },
    { value: 3, label: "Available" },
    { value: 4, label: "Unavailable" },
  ];

  const handleOptionChange = (event) => {
    const selectedValue = event.value;
    setSelectedOption(event);

    const selectedKey =
      selectedValue === 2
        ? "booked"
        : selectedValue === 3
        ? "available"
        : selectedValue === 4
        ? "unavailable"
        : "all";
    setSelectedDataKey(selectedKey);
  };

  const lineData = useMemo(() => ({
    labels: chartData.labels,
    datasets: [
      {
        data: chartData?.datasets?.map((data) =>
          selectedDataKey === "all"
            ? data.total_day_data
            : data.granular_data[selectedDataKey]
        ),
        borderColor: "#519ACC",
        borderWidth: 7,
        fill: true,
        backgroundColor: (context) => {
          if (!context.chart.chartArea) {
            return null;
          }

          const { ctx, chartArea } = context.chart;
          const gradient = ctx.createLinearGradient(
            80,
            chartArea.bottom,
            80,
            chartArea.top
          );
          gradient.addColorStop(1, "#519ACC");
          gradient.addColorStop(0, "#fff");
          gradient.addColorStop(0, "rgba(255, 255, 255, 0.5)");

          return gradient;
        },
        pointBackgroundColor: chartData?.datasets?.map((datapoint) => {
          const data =
            selectedDataKey === "all"
              ? datapoint.total_day_data
              : datapoint.granular_data[selectedDataKey];

          if (data >= 81) return "#B82A23";
          if (data >= 51) return "#2CB882";
          return "#1B92CC";
        }),
        pointBorderColor: "white",
        pointBorderWidth: 4,
        pointRadius: 8,
        pointDropShadow: true,
        pointHoverRadius: 9,
        pointBoxShadow: {
          enabled: true,
          color: "rgba(0, 0, 0, 0.2)",
          blur: 1,
          offsetX: 0,
          offsetY: 0,
        },
      },
    ],
  }));

  const pieData = useMemo(
    () => ({
      labels: doughnutData.labels,
      datasets: [
        {
          label: "-",
          data: doughnutData.present_data,
          backgroundColor: ["#386FFE", "#30A6DF", "#9C9C9C", "#D7D7D7"],
          hoverOffset: 4,
        },
      ],
    }),
    [doughnutData]
  );
  const bookedVacationUnavailablePercentage = (data = [], totalHours = 0) => {
    const [booked = 0, vacation = 0, unavailable = 0] = data || [];
    if (totalHours === 0) return 0;
    return Math.round(((booked + vacation + unavailable) / totalHours) * 100);
  };

  const presentPercentage = bookedVacationUnavailablePercentage(
    doughnutData.present_data_hours || [0, 0, 0, 0],
    doughnutData.present_total_hours || 0
  );

  const historicalPercentage = bookedVacationUnavailablePercentage(
    doughnutData.historical_data || [0, 0, 0, 0],
    doughnutData.historical_total_hours || 0
  );

  const percentageDifference = presentPercentage - historicalPercentage;

  const arrowDirection = percentageDifference >= 0 ? "up" : "down";
  const arrowColor = percentageDifference >= 0 ? "#519ACC" : "red";
  return (
    <BreakdownWrapper>
      <CapacityBreakdownContainer>
        <CapacityTitle>Capacity Breakdown</CapacityTitle>

        <div style={{ position: "absolute", top: "28%", left: "35.5%" }}>
          <DoughnutPercentage>{presentPercentage}%</DoughnutPercentage>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <svg
                width="15"
                height="8"
                viewBox="0 0 15 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d={
                    arrowDirection === "up"
                      ? "M0.33 7.69C0.54 7.89 0.83 8 1.13 8C1.43 8 1.71 7.89 1.92 7.69L7.51 2.52L13.09 7.69C13.30 7.88 13.59 7.99 13.88 7.99C14.18 7.99 14.46 7.88 14.67 7.68C14.88 7.49 15 7.23 15 6.95C15.00 6.68 14.89 6.41 14.68 6.22L8.30 0.31C8.09 0.11 7.81 0 7.51 0C7.21 0 6.92 0.11 6.71 0.31L0.33 6.22C0.12 6.41 0 6.68 0 6.96C0 7.23 0.12 7.50 0.33 7.69Z"
                      : "M0.33 0.31C0.54 0.11 0.83 0 1.13 0C1.43 0 1.71 0.11 1.92 0.31L7.51 5.48L13.09 0.31C13.30 0.12 13.59 0.01 13.88 0.01C14.18 0.01 14.46 0.12 14.67 0.32C14.88 0.51 15 0.77 15 1.05C15 1.32 14.89 1.59 14.68 1.78L8.30 7.69C8.09 7.89 7.81 8 7.51 8C7.21 8 6.92 7.89 6.71 7.69L0.33 1.78C0.12 1.59 0 1.32 0 1.05C0 0.77 0.12 0.51 0.33 0.31Z"
                  }
                  fill={arrowColor}
                />
              </svg>
              <span
                style={{
                  color: arrowColor,
                  fontSize: "20px",
                  fontWeight: "700",
                  fontFamily: "Manrope",
                }}
              >
                {Math.abs(percentageDifference)}%
              </span>
            </div>
            <DoughnutPreviousPeriodDate>
              Last: {endDate.clone().subtract(1, "year").format("MMM YYYY")}
            </DoughnutPreviousPeriodDate>
          </div>
        </div>
        <CapacityPie>
          <Doughnut
            data={pieData}
            height={250}
            width={250}
            options={{
              aspectRatio: 2.5,
              maintainAspectRatio: false,
              cutout: "70%",

              plugins: {
                legend: {
                  position: "bottom",
                  labels: {
                    font: {
                      size: 14,
                      family: "Manrope",
                      weight: "600",
                    },
                    boxWidth: 13,
                    boxHeight: 13,
                    padding: 16,
                    usePointStyle: true,
                  },
                },
                datalabels: {
                  display: false,
                },
              },
            }}
          />
        </CapacityPie>
        <CapacityHours>
          There are <span>{doughnutData.present_total_hours}</span> hours booked
          this period compared to{" "}
          <span>{doughnutData.historical_total_hours ?? 0}</span> last period.
        </CapacityHours>
      </CapacityBreakdownContainer>
      <CapacityOverTimeContainer>
        <CapacityOverTimeNavigation>
          <COTWrapper>
            <CapacityTitle>Capacity Over Time</CapacityTitle>
            <DateRange>
              {startDate.format("YYYY-MM-DD")} - {endDate.format("YYYY-MM-DD")}
            </DateRange>
          </COTWrapper>
          <COTDropdown>
            <DropdownMenu
              options={options}
              selectedOption={selectedOption}
              onOptionChange={handleOptionChange}
            />
          </COTDropdown>
        </CapacityOverTimeNavigation>
        <CapacityOverTimeChart>
          <Line
            data={lineData}
            pointBackgroundColor={["#519ACC", "#217a8a", "#D76F23"]}
            options={{
              hover: {
                mode: "nearest",
                intersect: true,
              },
              scales: {
                x: {
                  ticks: {
                    font: {
                      size: 14,
                      family: "Manrope",
                      weight: "600",
                    },
                    color: "#B0B0B0",
                    maxRotation: 55,
                    minRotation: 55,
                  },

                  grid: {
                    display: false,
                  },
                },
                yAxis: {
                  ticks: {
                    font: {
                      size: 15,
                      family: "Manrope",
                      weight: "700",
                      color: "#B0B0B0",
                    },
                    stepSize: 25,
                    callback: (value) => `${value}%`,
                  },
                  min: 0,
                  max: 100,
                },
              },
              aspectRatio: 2.5,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                  external: function (context) {
                    let tooltipEl = document.getElementById("custom-tooltip");

                    if (!tooltipEl) {
                      tooltipEl = document.createElement("div");
                      tooltipEl.id = "custom-tooltip";
                      tooltipEl.style.position = "absolute";
                      tooltipEl.style.background = "#519ACC";
                      tooltipEl.style.borderRadius = "6px";
                      tooltipEl.style.color = "#fff";
                      tooltipEl.style.padding = "8px";
                      tooltipEl.style.pointerEvents = "none";
                      tooltipEl.style.transition = "0.2s ease";
                      tooltipEl.style.display = "flex";
                      tooltipEl.style.alignItems = "center";
                      tooltipEl.style.justifyContent = "center";
                      document.body.appendChild(tooltipEl);
                    }

                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                      tooltipEl.style.opacity = "0";
                      return;
                    }

                    if (tooltipModel.body) {
                      const title = tooltipModel.title[0];
                      const bodyLines = tooltipModel.body.map(
                        (item) => item.lines[0]
                      );
                      const innerHtml = `
                        <div style="text-align: center; width: 102px; height: 46px;">
                          <strong style="font-weight: 700; font-family: Manrope; font-size: 15px;">${bodyLines[0]}%</strong><br/>
                          <span style="font-weight: 400; font-family: Arial; font-size: 12px;">${title} </span>
                        </div>`;
                      tooltipEl.innerHTML = innerHtml;
                    }

                    const position =
                      context.chart.canvas.getBoundingClientRect();
                    tooltipEl.style.left =
                      position.left +
                      window.scrollX +
                      tooltipModel.caretX -
                      tooltipEl.offsetWidth / 2 +
                      "px";
                    tooltipEl.style.top =
                      position.top +
                      window.scrollY +
                      tooltipModel.caretY -
                      tooltipEl.offsetHeight -
                      10 +
                      "px";

                    // Display tooltip
                    tooltipEl.style.opacity = "1";
                  },
                },
              },
            }}
          />
        </CapacityOverTimeChart>
        <CustomLegendContainer>
          <Legend>
            <Ellipsis color="#1B92CC" /> <LegendText>0 - 50%</LegendText>
          </Legend>
          <Legend>
            <Ellipsis color="#2CB882" /> <LegendText>51% - 80%</LegendText>
          </Legend>
          <Legend>
            <Ellipsis color="#B82A23" /> <LegendText>81% +</LegendText>
          </Legend>
        </CustomLegendContainer>
      </CapacityOverTimeContainer>
    </BreakdownWrapper>
  );
};

const BreakdownWrapper = styled.div`
  width: Fill (1, 344px) px;
  height: Hug (498px) px;
  gap: 8px;
  opacity: 0px;
  display: flex;
`;

const ArrowWrapper = styled.div`
  width: 28px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  border-radius: 5px;
  border: 1px;
  opacity: 0px;
  border: 1px solid #d7d7d7;
  cursor: pointer;
`;

const CapacityBreakdownContainer = styled.div`
  width: 368px;
  height: 498px;
  padding: 24px 32px;
  border-radius: 5px;
  opacity: 0px;
  text-align: center;
  border: 1px solid #d7d7d7;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  position: relative;
`;

const DoughnutPercentage = styled.span`
  font-family: Manrope;
  font-size: 48px;
  font-weight: 700;
`;

const DoughnutPreviousPeriodDate = styled.span`
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
`;
const CapacityOverTimeContainer = styled.div`
  width: 100%;
  height: 498px;
  padding: 24px;
  gap: 24px;
  border-radius: 5px;
  opacity: 0px;
  border: 1px solid #d7d7d7;
`;

const CapacityOverTimeChart = styled.div`
  height: 320px;
`;
const CapacityTitle = styled.div`
  width: 304px;
  height: 25px;
  gap: 0px;
  opacity: 0px;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 4px;
`;

const CapacityPie = styled.div`
  height: 312px;
  width: 268px;
  position: relative;
  z-index: 1;
`;

const CapacityHours = styled.div`
  font-family: Arial;
  font-size: 15px;
  font-weight: 400;

  > span {
    font-family: Arial;
    font-size: 15px;
    font-weight: 700;
    color: #519acc;
  }
`;

const CapacityOverTimeNavigation = styled.div`
  display: flex;
  justify-content: space-between;\
  margin-bottom: 24px;
`;

const COTWrapper = styled.div``;

const COTDropdown = styled.div``;

const DateRange = styled.span`
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;
`;

const CustomLegendContainer = styled.div`
  width: 259px;
  height: 19px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
`;

const Legend = styled.div`
  height: 19px;
  display: flex;
  gap: 8px;
  align-items: center;
`;
const Ellipsis = styled.div`
  width: 15px;
  height: 15px;
  background: ${({ color }) => color};
  border-radius: 50%;
`;

const LegendText = styled.span`
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
`;
export default BreakdownContainer;
